<template>
  <div class="text-center pb-12">
    <router-link :to="'/' + slug">
      <img
        :src="
          'https://firebasestorage.googleapis.com/v0/b/cbdcoas-com.appspot.com/o/public%2F' +
            slug +
            '%2Flogo?alt=media'
        "
        :alt="'Logo for ' + slug"
        width="300"
        height="150"
        class="mx-auto"
      />
    </router-link>
    <h1
      v-if="product.name"
      class="mt-8 text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10"
    >
      Certificate(s) of Analysis for: <br />
      <router-link :to="'/' + slug">
        {{ product.name }}
      </router-link>
    </h1>
    <h1
      v-else
      class="mt-8 text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10"
    >
      Certificate(s) of Analysis for: <br />
      <a :href="'https://' + slug" rel="noopener" target="_blank">{{
        store.company
      }}</a>
    </h1>
    <p v-if="product.data" class="mt-8">
      {{ product.data.description }}
    </p>
    <address class="mt-8 flex flex-col justify-center">
      <p>
        <a :href="'https://' + slug" rel="noopener" target="_blank"
          >https://{{ slug }}</a
        >
      </p>
      <p>
        <a :href="'mailto:' + store.email">{{ store.email }}</a>
      </p>
      <p>{{ store.address.line1 }}</p>
      <p>
        {{ store.address.city }}, {{ store.address.state }},
        {{ store.address.zip }}
      </p>
    </address>
  </div>
</template>

<script>
export default {
  props: {
    slug: {
      type: String,
      required: true
    },
    store: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
