<template>
  <table class="table-fixed mx-auto text-left">
    <tbody>
      <tr>
        <td
          class="w-1/4 border-b px-4 py-2 font-medium text-gray-500 leading-5"
        >
          UPC Code
        </td>
        <td class="w-3/4 border-b border-l px-4 py-2 leading-5 text-gray-900">
          {{ data.upc }}
        </td>
      </tr>
      <tr class="bg-gray-100">
        <td
          class="w-1/4 border-t border-b px-4 py-2 font-medium text-gray-500 leading-5"
        >
          CBD Per Container
        </td>
        <td
          class="w-3/4 border-t border-b border-l px-4 py-2 leading-5 text-gray-900"
        >
          {{ data.cbdPerContainer + "mg" }}
        </td>
      </tr>
      <tr>
        <td
          class="w-1/4 border-t border-b px-4 py-2 font-medium text-gray-500 leading-5"
        >
          CBD Per Use
        </td>
        <td
          class="w-3/4 border-t border-b border-l px-4 py-2 leading-5 text-gray-900"
        >
          {{ data.cbdPerUse ? data.cbdPerUse + "mg" : "N/A" }}
        </td>
      </tr>
      <tr class="bg-gray-100">
        <td
          class="w-1/4 border-t border-b px-4 py-2 font-medium text-gray-500 leading-5"
        >
          Uses Per Container
        </td>
        <td
          class="w-3/4 border-t border-b border-l px-4 py-2 leading-5 text-gray-900"
        >
          {{
            data.usesPerContainer ? data.usesPerContainer + " Approx." : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td
          class="w-1/4 border-t px-4 py-2 font-medium text-gray-500 leading-5"
        >
          Ingredients
        </td>
        <td class="w-3/4 border-t border-l px-4 py-2 leading-5 text-gray-900">
          <ul>
            <li v-for="ingredient in data.ingredients" :key="ingredient">
              <p>{{ ingredient }}</p>
            </li>
          </ul>
        </td>
      </tr>
      <!-- Flower:
        <tr>
          <td>Industrial Hemp License</td>
          <td><a href="#">License.pdf</a> <span class="checkmark" title="Certificate of Analysis has been verified"></span>
          </td>
        </tr>
        <tr>
          <td>Certificate of Analysis: Harvest</td>
          <td>Harvest.pdf</td>
        </tr>
        <tr>
          <td>COA: Processing/Extraction</td>
          <td>Extraction.pdf</td>
        </tr>
       -->
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
