<template>
  <table class="table-fixed">
    <thead>
      <tr class="text-left leading-5 text-gray-500">
        <th class="w-1/3 px-4 py-2">
          Batch Number
        </th>
        <th class="w-1/3 px-4 py-2 border-l border-r">
          Manufacture Date (MM/YY)
        </th>
        <th class="w-1/3 px-4 py-2">
          Expiration Date (MM/YY)
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, batch, index) in data"
        :key="batch"
        :class="{ 'bg-gray-100': index % 2 }"
      >
        <td class="border-t px-4 py-2 leading-5 text-gray-900">
          <a
            class="underline"
            target="_blank"
            :href="`${filestore}/${company}/products/${product}/batches/${batch}.png`"
          >
            {{ batch }}
          </a>
          <Checkmark v-if="item.verified" />
        </td>
        <td class="border-t border-l px-4 py-2 leading-5 text-gray-900">
          {{ item.mn }}
        </td>
        <td class="border-t border-l px-4 py-2 leading-5 text-gray-900">
          {{ item.ex }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Checkmark from "./Checkmark.vue";

export default {
  components: {
    Checkmark
  },
  props: {
    company: {
      type: String,
      required: true
    },
    product: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filestore:
        "https://watermark.cbdcoas.com"
    };
  }
};
</script>
