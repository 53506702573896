<template>
  <div class="bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
    <div class="relative max-w-lg mx-auto lg:max-w-7xl">
      <div>
        <h1
          class="text-3xl mb-12 leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          {{ item }} not found
        </h1>
        <p class="my-3 text-xl leading-7 text-gray-500 sm:mt-4">
          This {{ item.toLowerCase() }} could not be found, if you believe this
          is an error, please contact: support (at) cbdhexa.com
        </p>
        <router-link class="text-lg text-blue-700 hover:underline" to="/">
          Go to the homepage »
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: String,
      default: "Page"
    }
  }
};
</script>
