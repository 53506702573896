<template>
  <main>
    <div v-if="!loading && company && product">
      <Hero :slug="$route.params.slug" :store="company" :product="product" />
      <div class="bg-white overflow-hidden sm:rounded-lg">
        <div class="flex flex-col sm:flex-row px-4 py-5 sm:p-0">
          <div class="sm:w-1/2 mx-3">
            <ProductDetails :data="product" />
          </div>
          <div class="sm:w-1/2 mx-3">
            <BatchTable
              :company="$route.params.slug"
              :product="$route.params.product"
              :data="product.batches"
            />
          </div>
        </div>
        <p class="text-center mt-12 leading-6">
          <Checkmark /> = This certificate of analysis has been verified
        </p>
      </div>
    </div>
    <NotFound item="Product" v-if="!loading && (!product || !company)" />
  </main>
</template>

<script>
import Hero from "../../components/Hero.vue";
import ProductDetails from "../../components/ProductDetails.vue";
import BatchTable from "../../components/BatchTable.vue";
import Checkmark from "../../components/Checkmark.vue";
import NotFound from "../../components/NotFound.vue";

export default {
  components: {
    Hero,
    ProductDetails,
    BatchTable,
    Checkmark,
    NotFound
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    company() {
      return this.$store.state.companies[this.$route.params.slug];
    },
    product() {
      return this.$store.state.companies[this.$route.params.slug] &&
        this.$store.state.companies[this.$route.params.slug].products
        ? this.$store.state.companies[this.$route.params.slug].products[
            this.$route.params.product
          ]
        : null;
    }
  },

  metaInfo() {
    return {
      title: this.$route.params.product,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `Certificates of analysis for the batch(es) of ${this.$route.params.product}`
        }
      ]
    };
  },

  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.fetchData();
  },

  // Client-side only
  mounted() {
    // If we didn't already do it on the server
    // we fetch the item (will first show the loading text)
    if (!this.product) {
      // TODO SWAP THIS FOR INDIVIDUAL CALLS
      this.fetchData();
    } else {
      this.loading = false;
    }
  },

  methods: {
    fetchData() {
      // return the Promise from the action
      return this.$store
        .dispatch("fetchCompany", this.$route.params.slug)
        .then(() => (this.loading = false))
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
